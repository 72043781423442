<template>
  <div class="advanced-filters">
    <div
      v-for="(filter, index) in filters"
      :key="index"
      class="row d-flex align-items-center"
    >
      <div class="col-md-3">
        <multiselect
          :options="schema"
          label="label"
          track-by="name"
          v-model="filter.column"
          @input="handleColumnInput($event, filter)"
          :show-labels="false"
        ></multiselect>
      </div>
      <div class="col-md-2">
        <multiselect
          label="label"
          track-by="value"
          :disabled="filter.disabledOperator"
          :options="filter.operatorOptions"
          v-model="filter.operator"
          @input="handleOperatorInput($event, filter)"
          :show-labels="false"
        ></multiselect>
      </div>
      <div class="col-md-7 d-flex justify-content-between align-items-center">
        <multiselect
          v-if="filter.showMultiselect"
          :disabled="filter.disabledInput"
          :loading="false"
          :options="filter.options"
          label="option_name"
          :internal-search="true"
          v-model="filter.value"
          :multiple="true"
          track-by="option_id"
          @search-change="handleSearchChange($event, filter)"
          :show-labels="false"
          :show-no-options="false"
          :placeholder="
            !filter.disabledInput ? $gettext('Type to Search...') : ''
          "
        >
          <span slot="noResult"><translate>No results</translate></span>
        </multiselect>
        <b-form-input
          v-else-if="filter.showDatePicker"
          type="date"
          v-model="filter.value"
        ></b-form-input>
        <div v-else-if="filter.showDateRangePicker" class="row w-100">
          <div class="col-md-6">
            <b-input-group :prepend="$gettext('from')" class="mb-0">
              <b-form-input
                type="date"
                v-model="filter.value.start"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-md-6">
            <b-input-group :prepend="$gettext('to')" class="mb-0">
              <b-form-input
                type="date"
                v-model="filter.value.end"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <b-button
          variant="danger"
          pill
          @click="handleDeleteFilter(filter.id)"
          class="btn-icon btn-sm ml-3"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md text-right">
        <b-button variant="default" @click="addFilter" class="mr-2">
          <i class="fas fa-plus mr-2"></i>
          <translate>Add filter</translate>
        </b-button>
        <b-button variant="primary" @click="applyFilter">
          <translate>Apply filter</translate>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { createFilter, filtersToRequest } from "@/utils/filters";

export default {
  components: { Multiselect },
  props: {
    store: {
      required: true,
      type: String
    },
    shouldFetchSchema: {
      require: false,
      default: false
    }
  },
  computed: {
    schema() {
      return this.$store.getters[`${this.store}/schema`];
    },
    filters() {
      return this.$store.getters[`${this.store}/filters`];
    },
    filtersIsEmpty() {
      return this.filters.length === 0;
    }
  },
  methods: {
    async applyFilter() {
      await this.$store.commit(`${this.store}/setCurrentPage`, 1);
      await this.$store
        .dispatch(`${this.store}/paginate`)
        .finally(this.stopFetching);
      this.$emit("filter-applied");
    },
    addFilter() {
      return this.$store.commit(`${this.store}/addFilter`, createFilter());
    },
    handleLoadSavedFilter() {
      this.$bvModal.show(`load-saved-filter-modal-${this.store}`);
    },
    handleColumnInput(column, filter) {
      filter.setColumn(column);
    },
    handleOperatorInput(operator, filter) {
      filter.setOperator(operator);
    },
    handleSearchChange(q, filter) {
      if (filter.column.isEndpointOption) {
        const url = `ats/${filter.column.options.endpoint}?name=${filter.column.name}&q=${q}`;
        this.$http.get(url).then(({ data }) => (filter.options = data));
      }
    },
    filtersToRequest(filters) {
      return filtersToRequest(filters);
    },
    async handleDeleteFilter(index) {
      await this.$store.commit(`${this.store}/deleteFilter`, index);
      await this.$store.commit(`${this.store}/setCurrentPage`, 1);
      await this.$store
        .dispatch(`${this.store}/paginate`)
        .finally(this.stopFetching);
      this.$emit("filter-applied");
    }
  },
  async created() {
    if (this.shouldFetchSchema) {
      await this.$store.dispatch(`${this.store}/fetchSchema`);
    }
    if (this.filtersIsEmpty) {
      this.addFilter();
    }
  }
};
</script>
